@import '/src/styles/index';

.contactContainer {
  display: flex;
  justify-content: space-between;
  padding-right: 37px;
  flex-direction: row;
}
.contact {
  @include H1;
  color: $base-black01;
  padding-right: 30px;
}
.infoArea {
  width: 499px;
}

.title {
  @include B1;
  color: $base-black01;
}
.content {
  @include B3;
  width: 100%;
  color: $base-black01;
  padding-top: 11px;
  padding-bottom: 77px;
}

.iconContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-top: 17px;
  padding-right: 34px;
}

.icon {
  fill: $base-black01;
  height: 50px;
}
.icon:hover {
  transition: 0.5s;
  opacity: 0.6;
}
@media screen and (max-width: 600px) {
  .contactContainer {
    flex-direction: column;
    padding-right: 0px;
  }
  .contact {
    @include MH2;
    padding-bottom: 40px;
  }
  .title {
    @include MB1;
  }
  .content {
    padding-top: 10px;
    padding-bottom: 40px;
    @include MB3;
  }
  .iconContainer {
    justify-content: flex-start;
    padding-top: 10px;
    gap: 20px;
    padding-right: 0;
  }

  .icon {
    height: 36px;
  }
  .icon > svg {
    width: 36px;
    height: 36px;
  }
  .infoArea {
    width: 100%;
  }
}
