@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

$font-family: 'Inter';
$style: normal;

@mixin H0 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 700 !important;
  font-size: 74px;
  line-height: 90px;
}

@mixin H1 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 800 !important;
  font-size: 74px;
  line-height: 90px;
}
@mixin H2 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 700 !important;
  font-size: 58px;
  line-height: 72px;
  letter-spacing: -0.01em;
}
@mixin H3 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 700 !important;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.01em;
}

@mixin B0 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 700 !important;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.01em;
}
@mixin B1 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 700 !important;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.01em;
}
@mixin B2 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 600 !important;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.01em;
}
@mixin B3-600 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 600 !important;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.01em;
}
@mixin B3 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 400 !important;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.01em;
}
@mixin B4 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.01em;
}
@mixin B5 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;
}
@mixin B6 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 500 !important;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.01em;
}
/////////////////////////////////////
/////////// 모바일용 font /////////////
@mixin MH0 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 800 !important;
  font-size: 34px;
  line-height: 42px;
}

@mixin MH1 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 800 !important;
  font-size: 30px;
  line-height: 37px;
}
@mixin MH2 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 700 !important;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.01em;
}
@mixin MH3 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 700 !important;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.01em;
}
@mixin MH4 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 500 !important;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
}
@mixin MB1 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 700 !important;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;
}
@mixin MB1-14 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
}
@mixin MB2 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 600 !important;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
}
@mixin MB2-700 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 700 !important;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
}
@mixin MB3 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
}
@mixin MB3-600 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
}
@mixin MB4 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 400 !important;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: -0.01em;
}
@mixin MB6 {
  font-family: $font-family;
  font-style: $style;
  font-size: 14px;
  font-weight: 500 !important;
  line-height: 22px;
  letter-spacing: -0.01em;
}

@mixin MMB3 {
  font-family: $font-family;
  font-style: $style;
  font-weight: 400 !important;
  font-size: 11px;
  line-height: 17px;
  letter-spacing: -0.01em;
}

@mixin MCOPYRIGHT {
  font-family: $font-family;
  font-style: $style;
  font-weight: 400 !important;
  font-size: 12px;
  line-height: 30px;
  letter-spacing: -0.01em;
}
