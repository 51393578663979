@import 'src/styles/index';

.borderBox {
  border: 1px solid $base-white01;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 50px;
  background-color: $base-white01;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: content-box;

  &[data-radius='15'] {
    border-radius: 15px;
  }
  &[data-radius='20'] {
    border-radius: 20px;
  }
  &[data-radius='25'] {
    border-radius: 25px;
  }
  &[data-radius='30'] {
    border-radius: 30px;
  }
  &[data-radius='50'] {
    border-radius: 50px;
  }
  &[data-color='white'] {
    background-color: $base-white01;
    border: 1px solid $base-white01;
  }
  &[data-color='blue-white'] {
    background-color: $base-white02;
    border: 1px solid $base-white02;
  }
  &[data-color='gray'] {
    background-color: $base-warm-gray;
    border: 1px solid $base-warm-gray;
  }
  &[data-color='black'] {
    background-color: $base-black01;
    border: 1px solid $base-black01;
  }
}
@media screen and (max-width: 600px) {
  .borderBox {
    &[data-mradius='15'] {
      border-radius: 15px;
    }
    &[data-mradius='20'] {
      border-radius: 20px;
    }
    &[data-mradius='25'] {
      border-radius: 25px;
    }
    &[data-mradius='30'] {
      border-radius: 30px;
    }
    &[data-mradius='50'] {
      border-radius: 50px;
    }
  }
}
