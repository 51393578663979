@import '/src/styles/index';

.HrefContainer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 96px;
  row-gap: 80px;
}

.HrefContent {
  width: 528px;
  padding-left: 52px;
}
.topWrapper {
  position: relative;
}
.link {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 36px;
  height: 36px;
  left: calc(-18px - 16px);
}
.title {
  color: $base-black01;
  @include B1;
}

.contens {
  color: $base-black01;
  padding-top: 12px;
  @include B3;
}

@media screen and (max-width: 600px) {
  .HrefContainer {
    column-gap: 0px;
    row-gap: 40px;
    padding-left: 0;
    padding-right: 2px;
  }

  .HrefContent {
    width: auto;
    padding-left: 34px;
  }
  .topWrapper {
    position: relative;
  }
  .link {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 24px;
    height: 24px;
    left: calc(-12px - 10px);
  }
  .title {
    @include MB1;
  }

  .contens {
    padding-top: 8px;
    @include MB3;
  }
}
