@import 'src/styles/index';
.container {
  color: $base-white01;
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
  @include B5;
  cursor: pointer;
}

.global {
  background: url('../../assets/global/global_dark.svg');
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 16px;
  height: 16px;
  &[data-dark='true'] {
    background: url('../../assets/global/global_white.svg');
  }
}

.title {
  color: $base-black01;
  padding-left: 5px;
  padding-right: 7.75px;
  &[data-dark='true'] {
    color: $base-white01;
  }
  &[data-desktop='true'] {
    padding-left: 6px;
    padding-right: 3px;
  }
}

.arrow {
  background: url('../../assets/global/arrow_dark.svg');
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 16px;
  height: 16px;
  &[data-dark='true'] {
    background: url('../../assets/global/arrow_white.svg');
  }
}

.contentsWrap {
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  top: 52px;
  width: 80px;

  &[data-desktop='true'] {
    top: 80px;
    width: 86px;
  }
}
.area {
  padding: 10px;
  &[data-desktop='true'] {
    padding: 12px;
  }
}

.item {
  width: 62px;
  height: 52px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.item:hover {
  background-color: $base-white02;
  border-radius: 10px;
}

.item > p {
  text-decoration: none;
  color: black;
  padding-left: 20px;
}
