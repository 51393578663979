.menu {
  background: url('../../assets/menu/menu_black.svg');
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 32px;
  height: 100%;
  cursor: pointer;
  &[data-dark='true'] {
    background: url('../../assets/menu/menu_white.svg');
  }
  &[data-popup='true'] {
    background: url('../../assets/menu/mobile/close.svg') !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }
}
