@import '/src/styles/index';

.container {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  @include H2;
  font-size: 6.2rem;
  font-weight: bold;
  margin-bottom: 6rem;
}

.certifications {
  display: flex;
  justify-content: center;
  align-items: center;

  column-gap: 13.75rem;

  .certificate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .name {
      @include H3;
      font-size: 4.8rem;
      line-height: 6rem;
      font-weight: 700;
      margin-bottom: 3rem;
    }
  }
}

.viewButton {
  @include B2;

  display: flex;
  align-items: center;
  justify-content: center;

  column-gap: 1.2rem;

  &:hover {
    cursor: pointer;
    background-image: linear-gradient(to right, #9c6cff 0%, #05d2dd 100%);
  }

  border-radius: 1rem;

  .contentsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1.2rem;

    &:hover {
      img {
        filter: brightness(0) invert(100%);
      }
    }

    font-size: 1.2rem;
    line-height: 1.8rem;
    padding: 1.3rem 1.3rem;

    column-gap: 1rem;
    & > img {
      width: 1.4rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: auto;
  }

  .title {
    font-weight: bold;
    font-size: 2.8rem;

    margin-bottom: 3rem;
  }

  .certifications {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;

    gap: 4rem;

    .certificate {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .name {
        font-weight: 700;

        font-size: 2.4rem;
        line-height: 3.2rem;

        margin: 0.6rem 0 0.9rem;
      }

      width: 100%;
      height: 100%;
      border-radius: 1.5rem;
      justify-content: center;

      .certificateImg {
        width: 12rem;
      }
    }
  }

  .viewButton {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.2rem;
    letter-spacing: -0.01rem;

    padding: 0.1rem;
    border-radius: 1.5rem;
    background-color: white;
    box-shadow: none;
    border: 0px;
    // background-image: linear-gradient($base01, $base01), linear-gradient(to right, #9c6cff 0%, #05d2dd 100%);
    background-clip: content-box, border-box;

    display: flex;
    align-items: center;
    justify-content: center;

    column-gap: 1.2rem;

    &:hover {
      cursor: pointer;
      background-image: linear-gradient(to right, #9c6cff 0%, #05d2dd 100%);
    }

    border-radius: 1rem;
    .contentsContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 1.2rem;

      padding: 1.9rem 4rem;

      &:hover {
        // color: $base01;
        img {
          filter: brightness(0) invert(100%);
        }
      }

      font-size: 1.2rem;
      line-height: 1.8rem;
      padding: 1.3rem 1.3rem;

      column-gap: 1rem;
      & > img {
        width: 1.4rem;
      }
    }
  }
}
