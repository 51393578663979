@import 'src/styles/index';

.btn {
  width: 240px;
  height: 60px;
  border-radius: 15px;
  background-color: $base-black01;

  display: flex;
  justify-content: center;
  align-items: center;
}
.btn:hover {
  background-color: #46494b;
}
.btn > img {
  width: 120px;
  height: 30px;
}

@media screen and (max-width: 600px) {
  .btn {
    // width: 150px;
    width: 100%;
    height: 44px;
    border-radius: 10px;
  }
  .btn > img {
    width: 80px;
    height: 20px;
  }
}
