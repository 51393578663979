@import 'src/styles/index';

.moreBtn {
  display: flex;
  width: auto;
  height: 32px;
  padding: 0;
  margin: 0;

  text-decoration: none;
  cursor: pointer;
}
.moreBtn:hover {
  .text {
    color: $base-blue-gray;
  }
}

.text {
  @include B2;
  &[data-type='white'] {
    color: $base-white01;
  }
  &[data-type='black'] {
    color: $base-black01;
  }
  white-space: nowrap;
}
.text:hover {
  color: gray;
}
.img {
  padding-left: 20px;
}
.arrowIcon {
  fill: $base-red;
  width: 32px;
  height: 32px;
}

@media screen and (max-width: 600px) {
  .moreBtn {
    height: 24px;
  }
  .text {
    @include MB2;
  }
  .img {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
  .arrowIcon {
    width: 19.87px;
    height: 14.48px;
  }
}
