@import 'src/styles/index';

.wrapper {
  &[data-align='false'] {
    text-align: left;
  }
  &[data-align='true'] {
    text-align: center;
  }

  &[data-color='white'] {
    .title {
      color: $base-white01;
    }
    .desc {
      color: $base-white01;
    }
  }
  &[data-color='black'] {
    .title {
      color: $base-black01;
    }
    .desc {
      color: $base-black01;
    }
  }
}
.title {
  &[data-title='h0'] {
    @include H0;
  }
  &[data-title='h0'] > strong {
    @include H0;
  }
  &[data-title='h1'] {
    @include H1;
  }
  &[data-title='h1'] > strong {
    @include H1;
  }
  &[data-title='h2'] {
    @include H2;
  }
  &[data-title='h2'] > strong {
    @include H2;
  }
  &[data-title='h3'] {
    @include H3;
  }
  &[data-title='h3'] > strong {
    @include H3;
  }
}
.desc {
  &[data-desc='b1'] {
    @include B1;
  }
  &[data-desc='b3'] {
    @include B3;
  }
  &[data-desc='b3600'] {
    @include B3-600;
  }
  &[data-mindesc='423'] {
    min-width: 423px;
  }
}
@media screen and (max-width: 600px) {
  .wrapper {
    &[data-align='true'] {
      text-align: left;
    }
  }
  .title {
    &[data-title='h0'] {
      @include MH0;
    }
    &[data-title='h0'] > strong {
      @include MH0;
    }
    &[data-title='h1'] {
      @include MH1;
    }
    &[data-title='h1'] > strong {
      @include MH1;
    }
    &[data-title='h2'] {
      @include MH2;
    }
    &[data-title='h2'] > strong {
      @include MH2;
    }
    &[data-title='h3'] {
      @include MH3;
    }
    &[data-title='h3'] > strong {
      @include MH3;
    }
  }
  .desc {
    &[data-desc='b1'] {
      @include MB1-14;
    }
    &[data-desc='b3'] {
      @include MB3;
    }
    &[data-desc='b3600'] {
      @include MB3-600;
    }
  }
}
@media screen and (max-width: 423px) {
  .desc {
    &[data-mindesc='423'] {
      min-width: initial;
    }
  }
}
