@import '/src/styles/index';
.miniDataArea {
  display: grid;
  grid-template-columns: repeat(2, 300px);
  grid-template-rows: repeat(2, minmax(1px, auto));
  row-gap: 70px;
  column-gap: 158px;
}

.countBox {
  &:first-child {
    grid-column: 1/3;
    grid-row: 1/2;
  }
}

@media screen and (max-width: 600px) {
  .miniDataArea {
    display: flex;
    flex-direction: column;
    gap: 36px;
  }
}
