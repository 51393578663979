@import 'src/styles/index';

.box {
  display: flex;
  flex-direction: column;
  padding: 64px 0 0 64px;
}
.intro {
  width: 100%;
  z-index: 1;
}
.context {
  z-index: 1;
}
@media (max-width: 600px) {
  .box {
    padding-top: 24px !important;
    padding-right: 24px !important;
    padding-left: 24px !important;
    &[data-mbpadding='24'] {
      padding-bottom: 24px !important;
    }
    &[data-mbpadding='40'] {
      padding-bottom: 40px !important;
    }
    &[data-mtpadding='40'] {
      padding-top: 40px !important;
    }
  }
}
