.text {
  width: 100%;
  padding-top: 200px;
  padding-bottom: 200px;
}

.img {
  max-width: 600px;
  max-height: 742px;
  width: initial !important;
  height: initial !important;
  margin-right: 70px;
}
.btnWrapper {
  display: flex;
  gap: 24px;
}
@media screen and (max-width: 980px) {
  .text {
    padding-top: 0;
    padding-bottom: 0;
  }
  .img {
    margin-right: 0;
  }
}

@media screen and (max-width: 600px) {
  .img {
    max-width: 400px;
    max-height: 266.8px;
  }
  .btnWrapper {
    justify-content: space-between;
    gap: 11px;
  }
  .btnWrapper > a {
    width: 100%;
  }
}
@media screen and (max-width: 355px) {
  .btnWrapper {
    flex-wrap: wrap;
  }
}
