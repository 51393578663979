.moreBtnRight {
  display: flex;
  flex-direction: row-reverse;
}
.bgImg {
  position: absolute;
  background-image: url('./assets/background/Union.svg');
  background-repeat: no-repeat;
  background-position: right -60px;
  background-size: 590px;
  width: 100%;
  height: 100%;
  top: 0;
  right: -25px;
}
@media screen and (max-width: 600px) {
  .bgImg {
    background-position: right -30px;
    background-size: 240px;
    right: -30px;
  }
}
