.mainSection {
  background-position: 0px top;
  background-repeat: no-repeat;

  &[data-page='Validator'] {
    background-position-x: 424px;
    background-position-y: 195px;
    background-image: url('./assets/background/validator.png');
  }

  &[data-page='Service'] {
    background-position-x: 574px;
    background-position-y: 39px;
    background-image: url('./assets/background/service.png');
  }

  &[data-page='Ventures'] {
    background-position-x: 480px;
    background-image: url('./assets/background/ventures.png');
  }

  &[data-page='About'] {
    background-position-x: 222px;
    background-position-y: -223px;
    background-image: url('./assets/background/about.png');
  }
}

@media screen and (max-width: 600px) {
  .mainSection {
    background-repeat: no-repeat;
    &[data-page='Validator'] {
      background-image: url('./assets/background/mValidator.png');
      background-position-x: -146px;
      background-position-y: 125px;
    }

    &[data-page='Service'] {
      background-image: url('./assets/background/mService.png');
      background-position-x: -147px;
      background-position-y: 102px;
    }

    &[data-page='Ventures'] {
      background-image: url('./assets/background/mVentures.png');
      background-position-x: -176px;
      background-position-y: 25px;
    }

    &[data-page='About'] {
      background-image: url('./assets/background/mAbout.png');
      background-position-x: -96px;
      background-position-y: 43px;
    }
  }
}

@media screen and (min-width: 1921px) {
  .mainSection {
    &[data-page='Validator'] {
      background-position-x: calc(50% + 420px);
      background-position-y: 195px;
    }

    &[data-page='Service'] {
      background-position-x: calc(50% + 500px);
      background-position-y: 39px;
    }

    &[data-page='Ventures'] {
      background-position-x: calc(50% + 440px);
    }

    &[data-page='About'] {
      background-position-x: calc(50% + 485px);
      background-position-y: -223px;
    }
  }
}
