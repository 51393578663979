@import 'src/styles/index';

.container {
  width: 100%;
  text-align: left;
  box-sizing: content-box;
}
.img {
  height: 84px;
}
.title {
  padding-top: 16px;

  @include B1;
  color: $base-black01;
}
.desc {
  max-width: 520px;
  width: 100%;
  padding-top: 10px;

  @include B3;
  color: $base-black01;
}

@media screen and (max-width: 600px) {
  .img {
    width: 60px;
    height: 60px;
  }
  .title {
    @include MB1;
    padding-top: 10px;
  }
  .desc {
    @include MB3;
    padding-top: 8px;
  }
}
