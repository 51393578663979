@import '/src/styles/index';

.title {
  @include H2;
  color: $base-black01;
  padding-bottom: 60px;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.card {
  width: 198px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: '600px') {
  .title {
    @include MH2;
    padding-bottom: 20px;
  }
  .cardContainer {
    gap: 12px;
  }
  .card {
    width: calc((100% - 12px * 2) / 3) !important;
    height: calc((100vw - 12px * 2 - 28px * 2) / 3) !important;
  }
}
