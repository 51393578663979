@import 'src/styles/index';
.wrapper {
  &[data-color='white'] {
    color: $base-white01;
  }
  &[data-color='black'] {
    color: $base-black01;
  }
}
.title {
  @include B0;
  padding-bottom: 18px;
  &[data-title='b2'] {
    @include B2;
  }
}
.numValue {
  @include H3;
}

@media screen and (max-width: 600px) {
  .title {
    @include MB2;
    padding-bottom: 6px;
    &[data-mtitle='b2-700'] {
      @include MB2-700;
    }
  }
  .numValue {
    @include MH3;
  }
}
