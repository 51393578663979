.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.imgArea > img {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 980px) {
  .wrapper {
    flex-direction: column-reverse;
  }
  .imgArea > img {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 600px) {
}
