@import 'src/styles/index';

.title {
  @include B3;
  color: $base-white01;
  margin-bottom: 12px;
}
.contents {
  @include B4;
}
.contents > a {
  text-decoration: none;
  color: $base-blue-gray;
  cursor: pointer;
}
.contentItem {
  white-space: nowrap;
  margin-bottom: 8px;
}
.contentItem:hover {
  color: $base-white01;
}

@media screen and (max-width: 600px) {
  .item {
    padding-bottom: 30px;
  }
  .title {
    margin-bottom: 10px;
    @include MB3;
  }
  .contents {
    @include MB4;
  }
  .contentItem {
    margin-bottom: 4px;
  }
}
@media screen and (max-width: 380px) {
  .contentItem {
    white-space: normal;
  }
}
@media screen and (max-width: 924px) {
  .item {
    padding-bottom: 30px;
  }
}
