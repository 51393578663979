.boxFrame {
  width: 403px;
  &[data-height='440'] {
    height: 440px;
  }
  &[data-height='446'] {
    height: 446px;
  }
}
.content {
  padding: 60px 20px 0 50px;
}

@media screen and (max-width: 600px) {
  .boxFrame {
    width: 100%;
    &[data-height='440'] {
      height: 288px;
    }
    &[data-height='446'] {
      height: 320px;
    }
  }
  .content {
    padding: 50px 24px 0 24px;
  }
}
