.frame {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  padding-top: 80px;
  &[data-is2x2='true'] {
    row-gap: 80px;
  }
}
@media screen and (max-width: 1115px) {
  .frame {
    &[data-is2x2='false'] {
      row-gap: 80px;
    }
  }
}
@media screen and (max-width: 600px) {
  .frame {
    padding-top: 40px;
    row-gap: 60px !important;
  }
}
