.videoContainer {
  height: 1080px;
  position: relative;
  overflow: hidden;
}
.video {
  position: absolute;
  height: 1080px;
  top: 0;
  right: 0%;
}
.intro {
  position: relative;
  padding-top: 320px;
  padding-left: 28px;
  padding-right: 10px;
  max-width: 1920px - 280px * 2 + 38px;
  margin: auto;
}
@media screen and (max-width: '1300px') {
  .video {
    transition: 1s;
    right: -10%;
  }
}

@media screen and (max-width: '1000px') {
  .video {
    transition: 1s;
    right: -25%;
  }
}

@media screen and (max-width: 600px) {
  .video {
    transition: none;
    height: 100%;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .video[poster] {
    width: 100%;
    height: auto;
  }
  .videoContainer {
    height: 812px;
  }
  .intro {
    padding-top: 464px;
    max-width: none;
  }
}

@media screen and (max-width: 375px) {
  .video[poster] {
    width: auto;
    height: 100%;
  }
}

@media screen and (min-width: '1920px') {
  .videoContainer {
    height: calc(100vw * 9 / 16);
  }
  .video {
    transition: none;
    height: 100%;
  }
}
