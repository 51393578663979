.wrapper {
  position: relative;
}
.textWrapper {
  padding-right: auto;
}
.img {
  position: absolute;
  max-width: 600px;
  max-height: 600px;
  width: 100%;
  height: 100%;
  right: -10%;
  top: 25%;
}

@media screen and (max-width: 1250px) {
  .img {
    width: 500px;
    height: 500px;
    right: -5%;
    top: 20%;
  }
}
@media screen and (max-width: 1123px) {
  .img {
    width: 400px;
    height: 400px;
    right: -5%;
    top: 30%;
  }
}
@media screen and (max-width: 980px) {
  .wrapper {
    margin-top: 200px;
  }
  .img {
    width: 500px;
    height: 500px;
    left: calc(50% - 250px);
    top: -10%;
  }
  .textWrapper {
    padding-top: 5%;
    padding-right: 0;
  }
}
@media screen and (max-width: 600px) {
  .wrapper {
    margin-top: 0;
    transition: 0s;
  }
  .img {
    width: 300px;
    height: 300px;
    left: calc(50% - 150px);
    top: 20%;
    transition: 0s;
  }
}
