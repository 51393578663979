.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.titleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img {
  width: 100%;
  height: auto;
  max-width: 969px;
  max-height: 553px;
}
@media screen and (max-width: 600px) {
  .titleWrapper {
    align-items: start;
  }
  .container {
    flex-direction: column-reverse;
  }
  .container {
    align-items: stretch;
  }
}
