@import 'src/styles/index';
.overFlower {
  width: 100%;
  overflow-x: hidden;
}

.contentsFrame {
  width: 100%;
  margin: auto;
  &[data-screen='main'] {
    max-width: 1290px + $main-frame-padding * 2;
    padding: 0 $main-frame-padding 0 $main-frame-padding;
  }
  &[data-screen='nav'] {
    height: 100%;
    max-width: 1388px;
  }
  &[data-screen='video'] {
    max-width: none;
  }
  &[data-screen='footer'] {
    max-width: 1360px;
  }
}
@media screen and (max-width: '600px') {
  .contentsFrame {
    &[data-screen='main'] {
      margin: 0;
    }
    &[data-screen='nav'] {
      max-width: none;
    }
  }
}
