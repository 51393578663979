.introContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  flex: 1 0 auto;
}
@media screen and (max-width: '900px') {
  .introContainer {
    flex-wrap: wrap;
  }
}

.area {
  height: 860px;
  width: 100%;
}

.intro {
  height: 100%;
  padding: 64px 30px 64px 64px;
  &[data-background='atom'] {
    background-image: url('./assets/atom.png');
    background-repeat: no-repeat;
    background-position-x: -195px;
    background-position-y: 357px;
  }
  &[data-background='cube'] {
    background-image: url('./assets/cube.png');
    background-repeat: no-repeat;
    background-position-x: -305px;
    background-position-y: 293px;
  }
}
@media screen and (max-width: 600px) {
  .area {
    height: 400px;
  }
  .introContainer {
    gap: 20px;
  }

  .intro {
    height: 100%;
    padding: 24px 24px 24px 24px;
    &[data-background='atom'] {
      background-image: url('./assets/atom_mobile.png');
      background-repeat: no-repeat;
      background-position-x: calc(100% + 191px);
      background-position-y: 157px;
    }
    &[data-background='cube'] {
      background-image: url('./assets/cube_mobile.png');
      background-repeat: no-repeat;
      background-position-x: calc(100% + 242px);
      background-position-y: 135px;
    }
  }
}
