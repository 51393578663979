$black: #000000;

$base-white01: rgba(255, 255, 255, 1);
$base-black01: #1b1c1d;

$base-warm-gray: #f2f4f5;
$base-blue-gray: #757880;
$base-deep-gray: #4a4d53;

$base-red: #db2222;

$base-white02: #fbfcff;

$background-color: rgba(251, 252, 255, 1);

$base-black05: #0e0e10;
