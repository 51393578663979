@import '/src/styles/index';

.checkContainer {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.checkContext {
  padding-left: 56px;
  &[data-width='270'] {
    max-width: 270px;
  }
  &[data-width='900'] {
    max-width: 900px;
  }
  &[data-width='950'] {
    max-width: 950px;
  }
}
.topWrapper {
  position: relative;
}
.check {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 36px;
  height: 36px;
  left: calc(-18px - 20px);
}

.title {
  color: $base-black01;
  @include B1;
}

.contents {
  padding-top: 12px;
  color: $base-black01;
  @include B3;
}

@media screen and (max-width: 600px) {
  .checkContainer {
    gap: 60px;
  }
  .checkContext {
    padding-left: 36px;
  }
  .check {
    width: 24px;
    height: 24px;
    left: -36px;
    left: calc(-12px - 12px);
  }
  .textWrapper {
    gap: 10px;
  }
  .title {
    @include MB1;
  }
  .contents {
    @include MB3;
    padding-top: 10px;
  }
}
