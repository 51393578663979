@import '/src/styles/index';

.portfolioCard {
}

.title {
  @include H1;
  color: $base-black01;
  padding-bottom: 60px;
}
.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}
.card {
  width: 403px;
  height: 460px;
}
.companyCard {
  padding: 64px 40px 64px 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.company {
  @include B1;
  color: $base-black01;
  padding-bottom: 10px;
}

.desc {
  color: $base-black01;
  @include B3;
}

@media screen and (max-width: 600px) {
  .title {
    @include MH1;
    padding-bottom: 20px;
  }
  .cardContainer {
    gap: 13px;
  }
  .card {
    width: calc((100% - 13px) / 2);
    height: 176px;
  }
  .company {
    @include B1;
  }

  .desc {
    @include B3;
  }
}
