@import 'src/styles/index';

.dataBox {
  background-image: url('./assets/validator_demain.png');
  background-repeat: no-repeat;
  background-position-x: 3px;
  background-position-y: 33px;

  height: 820px;
  padding: 64px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bottomWrapper {
  display: grid;
  grid-template-columns: repeat(2, 306px);
  grid-template-rows: repeat(2, minmax(1px, auto));
  row-gap: 70px;
  column-gap: 37px;
}
.counter {
  &:first-child {
    grid-column: 1/3;
    grid-row: 1/2;
  }
}
@media screen and (max-width: 600px) {
  .dataBox {
    height: 580px;
    padding: 24px 24px 0 24px;
    background-image: url('./assets/validator_demain_mobile.png');
    background-size: auto;
    background-position-x: calc(100% + 306px);
    background-position-y: 83px;
  }
  .bottomWrapper {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .counter {
    border-top: 1px solid $base-deep-gray;
    margin-left: -24px;
    padding: 14px 24px;
  }
}
