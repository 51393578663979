@import 'src/styles/index';
.container {
  height: 100%;
  @include B5;
}
.container > a {
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
}

.dark {
  color: $base-black01;
}

.white {
  color: $base-white01;
}

.contentsWrap {
  position: absolute;
  color: $black;
  display: flex;
  flex-direction: column;
  top: 80px;
  width: 240px;
}

.area {
  padding: 12px;
}

.item {
  width: 216px;
  height: 52px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  text-decoration: none;
  color: $black;
  padding-left: 20px;
}

.item:hover {
  background-color: $base-white02;
  border-radius: 10px;
}
