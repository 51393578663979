@import 'src/styles/index';

.wrapper {
  display: flex;
  gap: 20px;
  justify-content: center;
}
.logo {
  color: $base-blue-gray;

  &[data-nav='false']:hover {
    color: $base-white01;
  }
  &[data-nav='true']:hover {
    filter: opacity(0.6);
  }
}
