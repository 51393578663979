.textWrapper {
  width: 100%;
  height: 100%;
  padding: 13% 0;
}
.img {
  margin-left: 12%;
}
@media screen and (max-width: 980px) {
  .textWrapper {
    padding: 0;
  }
  .img {
    margin-left: 0;
  }
}
