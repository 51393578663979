@import 'src/styles/index';
.area {
  display: block;
  width: 100%;
  background-color: $base-black01;
  overflow: hidden;
}
.wrapper {
  height: 100%;
  padding: 52px 28px 27px 28px;
}
.topWrapper {
  display: flex;
  justify-content: space-between;
}
.img {
  width: 160px;
  height: 36.6px;
  margin-right: 150px;
}
.itemWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.bottomWrapper {
  display: flex;
  padding-top: 100px;
  justify-content: space-between;
}

.serviceFooter {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  letter-spacing: -0.016rem;
  font-weight: 500;

  .privacyLink {
    margin-right: 2rem;
    font-weight: bold;
    color: $base-red;
    text-decoration: none;
    &:hover {
      color: $base-white01;
    }
  }
}

.copyright {
  @include B4;
  color: $base-blue-gray;
}

////////////////////////////////////
////////////////////////////////////
@media (max-width: 600px) {
  .area {
    padding: 28px 28px 0px 28px;
  }
  .img {
    width: 131px;
    height: 31px;
    padding-bottom: 30px;
    box-sizing: content-box;
  }
  .wrapper {
    padding: 0 0 27px 0;
  }
  .topWrapper {
    flex-direction: column;
    justify-content: flex-start;
  }

  .bottomWrapper {
    flex-direction: column-reverse;

    align-items: center;
  }
  .copyright {
    padding-top: 10px;
    @include MCOPYRIGHT;
  }

  .serviceFooter {
    display: flex;
    flex-direction: column;

    row-gap: 1rem;
    align-items: center;
    font-size: 1.6rem;
    letter-spacing: -0.016rem;
    font-weight: 500;

    .privacyLink {
      margin-right: 1rem;
      font-weight: bold;
      color: $base-red;
      text-decoration: none;
      &:hover {
        color: $base-white01;
      }
    }
  }
}
@media screen and (max-width: 924px) {
  .itemWrapper {
    justify-content: left;
    gap: 30px;
  }
}
@media screen and (max-width: 700px) {
  .img {
    margin-right: 80px;
  }
  .bottomWrapper {
    padding-top: 80px;
  }
  .itemWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 12px;
  }
}
@media screen and (max-width: 600px) {
  .bottomWrapper {
    padding-top: 20px;
  }
}
