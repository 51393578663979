@import '/src/styles/index';

.ConsultConatiner {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.card {
  width: 300px;
  height: 300px;
}
.company {
  color: $base-black01;
  @include B1;
  margin-top: 18px;
}

@media screen and (max-width: 600px) {
  .ConsultConatiner {
    gap: 13px;
  }
  .card {
    width: calc((100% - 13px) / 2);
    height: 153px;
  }
  .company {
    @include MB1;
    margin-top: 9px;
    margin-bottom: 30px;
  }
}
