@import '/src/styles/index';

.menuContainer {
  padding-left: 20px;
  padding-right: 12px;
}
.menuTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
}
.title {
  @include MB1;
  height: fit-content;
  text-decoration: none;
  color: $base-black05;
}
.dropdown {
  cursor: pointer;
  background-image: url('../../../../assets/menu/mobile/dropdown.svg');
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  &[data-isdropped='true'] {
    background-image: url('../../../../assets/menu/mobile/dropup.svg');
  }
}
.menuItemContainer {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  gap: 16px;
}
.menuItem {
  width: fit-content;
  @include MH4;
  color: $base-blue-gray;
  text-decoration: none;
}
