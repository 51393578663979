.smallContextWrapper {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  gap: 40px;
}

@media screen and (max-width: 600px) {
  .smallContextWrapper {
    gap: 20px;
  }
}
