@import '/src/styles/index';
.mobileMenu {
  position: fixed;
  z-index: 9;
  background-color: $base-white02;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 54px;
  padding-bottom: 32px;
}
