@import 'src/styles/index';

.card {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

a.card:hover > .background {
  filter: opacity(0.6);
}

.background {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  border-radius: 50%;

  &[data-align='center'] {
    background-position: center;
  }
  &[data-align='end'] {
    background-position: center bottom;
  }
  &[data-size='120'] {
    background-size: 120px;
    width: 120px;
    height: 120px;
  }
  &[data-size='140'] {
    background-size: 140px;
    width: 140px;
    height: 140px;
  }
}

@media screen and (max-width: 600px) {
  .background {
    background-size: contain;
    border-radius: 0;

    &[data-msize='60'] {
      background-size: 60px;
      width: 60px;
    }
    &[data-msize='88'] {
      background-size: 88px;
      width: 88px;
      height: 88px;
    }
    &[data-msize='130'] {
      background-size: 130px;
      width: 130px;
    }
    &[data-size='130'] {
      background-size: 130px 50px !important;
    }
  }
}
