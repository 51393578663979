@import 'src/styles/index';

.nav_section {
  top: 0;
  left: 0;
  right: 0;
  position: sticky;
  margin-top: -80px;
  height: 80px;
  transition: 0.25s;
  background-color: $base-white01;
  z-index: 10;

  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);

  &[data-dark='true'] {
    background-color: transparent;
    box-shadow: none;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;

  max-width: none;
  padding-left: 16px;
  padding-right: 12px;
  height: 100%;
}
.container > a {
  height: 100%;
}
.logo {
  height: 100%;
  display: flex;
  align-items: center;

  background: url('./assets/logo/stamper_dark.svg');
  width: 161px;

  background-repeat: no-repeat !important;
  background-position: center !important;

  &[data-dark='true'] {
    background: url('./assets/logo/stamper_white.svg');
  }
}

.navItem {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 70px;
}

@media screen and (max-width: 980px) {
  .nav_section {
    margin-top: -54px;
    height: 54px;
  }
  .logo {
    background: url('./assets/logo/m_stamper_dark.svg');
    width: 131px;
    &[data-dark='true'] {
      background: url('./assets/logo/m_stamper_white.svg');
    }
  }
  .navItem {
    gap: 18px;
  }
}
